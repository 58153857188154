
$white:    #fff;
$ghost:    #fdfdff;
$smoke:    #f8f8f8;
$gray-110: #fcfdfe;
$gray-120: #f4f7fa;
$gray-210: #e7e9ed;
$gray-opacity:   rgba(231, 233, 237, 0.13);
$gray-310: #d5d7dd;
$storm:    #7d818d;
$gray-610: #666666;
$gray-710: #413e65;
$gray-910: #1F1F1F;
$gray-920: #1e1e20;
$gray-930: #19191b;
$gray-940: #161c2d;
$black:    #000 ;



$red:                   #f64b4b;
$blue:                  #473bf0;
$sky-blue:              #1082e9;
$green:                 #68d585;
$green-shamrock:        #2bd67b;
$blackish-blue:         #13151C;
$blackish-blue-opacity: rgba(#161c2d,.7);
$mirage:                #131829;
$yellow: #f7e36d;
$yellow-orange: #FCAD38;
$narvik: #EDF9F2;

$primary:       $blue;
$secondary:     $green;

